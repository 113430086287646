<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.2.4/gsap.min.js"></script>

<template>
  <div class="container">
    <div
      class="columns is-variable is-1-mobile is-multiline is-mobile is-multiline"
      style="padding-bottom: 30px;"
    >
      <div class="column is-full">
        <h5>Estabelecimento: {{ products.name }}</h5>
      </div>
      <div class="column is-6">
        <div class="tags has-addons">
          <span class="tag">Capacidade </span>
          <span class="tag is-primary">{{ products.maxQtd }}</span>
        </div>
      </div>
      <div class="column is-6">
        <div class="tags has-addons">
          <span class="tag">Status</span>
          <span
            v-if="Number(products.actualQty) / Number(products.maxQtd) < 0.5"
            class="tag is-primary"
          >
            Normal</span
          >
          <span
            v-if="
              Number(products.actualQty) / Number(products.maxQtd) >= 0.5 &&
                Number(products.actualQty) / Number(products.maxQtd) <= 1
            "
            class="tag is-warning"
          >
            Atenção</span
          >
          <span
            v-if="Number(products.actualQty) / Number(products.maxQtd) > 1"
            class="tag is-danger"
          >
            Perigo (+{{
              Number(products.actualQty) - Number(products.maxQtd)
            }}
            )</span
          >
        </div>
      </div>
      <div class="column is-full box" style="padding: 20px;">
        <h1 class="title has-text-center">
          <i class="fas fa-users has-text-grey-lighter"></i>
          {{ products.actualQty }}
        </h1>
      </div>

      <div v-for="key in keys" :key="key.value" class="column is-4">
        <button
          :disabled="products.actualQty <= 0 && key.value < 0"
          class="btn is-fullwidth is-large btn-success"
          :class="{
            'btn-danger': key.value < 0
          }"
          @click="incrementOrDecrease(key.value)"
        >
          {{ key.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase, { firestore, database } from "firebase/app";
import "firebase/firestore";
import { mapState, mapMutations } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      keys: [
        { label: "+1", value: 1 },
        { label: "+2", value: 2 },
        { label: "+3", value: 3 },
        { label: "+4", value: 4 },
        { label: "+5", value: 5 },
        { label: "-1", value: -1 },
        { label: "-2", value: -2 },
        { label: "-3", value: -3 },
        { label: "-4", value: -4 },
        { label: "-5", value: -5 }
      ],
      showHistory: false
    };
  },
  computed: {
    ...mapState("locations", [
      "actualQty",
      "products",
      "footFallHistory",
      "showHistory"
    ]),
    ...mapState("costumers", ["locationId"])
  },
  created() {
    this.checkParamsForUrl();
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations("costumers", ["setLocationId"]),
    ...mapMutations("locations", ["setFootFallHistory"]),
    checkParamsForUrl() {
      if (router.currentRoute.params.locationId) {
        this.$store.commit(
          "costumers/setLocationId",
          router.currentRoute.params.locationId
        );
        this.getActualQty();
      }
    },
    incrementOrDecrease(value) {
      value = value;
      let increment;
      // Avoid qtyTotal being negative
      if (value < 0 && this.products.actualQty + value < 0) {
        increment = 0;
      } else {
        increment = firebase.firestore.FieldValue.increment(value);
      }

      firebase
        .firestore()
        .collection("locations")
        .doc(this.locationId)
        .update({ actualQty: increment })
        .then(() => {
          this.$buefy.toast.open({
            type: "is-info",
            message: `<i class="fas fa-user"></i> ${value}`
          });
        });
    },
    getActualQty() {
      firebase
        .firestore()
        .collection("locations")
        .doc(this.locationId)
        .onSnapshot(snap => {
          this.$store.commit("locations/setProducts", snap.data());
        });
    }
  }
};
</script>
<style scoped>
.btn {
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;

  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  width: 79.42px;
}

.btn-success {
  background-color: #48c774;
}

.btn-danger {
  background-color: #f44336;
}

.btn:hover {
  opacity: 0.9;
}

.btn:active {
  background-color: gray;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
