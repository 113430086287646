<template>
  <div class="page-wrapper">
    <div class="columns is-centered ">
      <div class="column is-4 box">
        <people-keypad> </people-keypad>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PeopleKeypad from '@/components/locations/PeopleKeypad'

export default {
  components: { PeopleKeypad },
  props: {
    id: String
  },

  computed: {
    ...mapGetters('locations', ['getProductById']),
    currentProduct() {
      return this.getProductById(this.id)
    }
  }
}
</script>

<style>
@media print {
  .navbar,
  h1 {
    visibility: hidden;
  }
}
</style>
